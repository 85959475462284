import moment from "moment";

export const convertUTCTimeToLocalTime = (UTCDate) => {
    var stillUtc = moment.utc(UTCDate, 'YYYYMMDDHHmmss').toDate();
    const formattedLocalDate = moment.utc(stillUtc).local().format('MM/DD/YYYY HH:mm:ss');

    return formattedLocalDate ? formattedLocalDate : "";
}

export const roundToTwoDecimalPlaces = (value) => {
    const number = Number(value); // Convert to number
    if (isNaN(number)) {
        throw new Error("Input is not a valid number");
    }
    return Number(number.toFixed(2)); // Return as a number with 2 decimal places
}
