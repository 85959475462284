import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { Grid, Button } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment";
import { fetchShipments } from "../../api/shipmentTable";
import { useTheme } from "@mui/material/styles";
import config from "../../api/config";
import InfiniteScroll from "react-infinite-scroll-component";
import { useToastContext } from "../../context/toast";

const formatDeliveryStatus = (status) => {
    if (!status) return "";
    const statusMap = {
        "Intransit": "In Transit",
    };
    return statusMap[status] || status;
};

function Row(props) {
    const { row, index, columns } = props;
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    const cellBorderStyle = row.DeliveryStatus === "Missed" ? "4px solid red" : "none";

    const formatMultipleValues = (value) => {
        if (!value) return "";
    
        // Split the input string by "|", trim each item, and create a Set to filter unique values
        const uniqueValues = [...new Set(value.split("|").map(item => item.trim()))];
    
        return uniqueValues.map((item, index) => (
            <React.Fragment key={index}>
                {index > 0 && <br />}
                {item}
            </React.Fragment>
        ));
    };

    return (
        <>
            <TableRow
                sx={{
                    "& > *": {
                        backgroundColor:
                            index % 2 === 0 ? theme.palette.primary.main : theme.palette.secondary.main,
                    },
                }}
            >
                {columns.map((column) => (
                    <TableCell
                        key={column.id}
                        sx={{
                            width: column.width,
                            padding: "12px 8px",
                            borderLeft: column.id === "DeliveryNumber" ? cellBorderStyle : "none",
                            textAlign: column.id === "DeliveryNumber" ? "left" : "left",
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                            verticalAlign: "top",
                        }}
                    >
                        {column.id === "DeliveryNumber" ? (
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <IconButton size='small' onClick={() => setOpen(!open)} sx={{ marginRight: "8px" }}>
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                                <span>{formatMultipleValues(row.DeliveryNumber)}</span>
                            </Box>
                        ) : column.id === "PONumber" ? (
                            formatMultipleValues(row.ShipmentDetails?.PONumber)
                        )  : column.id === "WareHouseNbr" ? (
                            row?.WareHouseNbr
                        ) : column.id === "City" ? (
                            row.City
                        ) : column.id === "State" ? (
                            row?.State
                        ) : column.id === "DeliveryAppointment" ? (
                            row.DeliveryAppointment ? (
                                <Box>
                                    <div>
                                        {moment(row.DeliveryAppointment, "YYYYMMDDHHmmss").format("MM/DD/YYYY")}
                                    </div>
                                    <div>{moment(row.DeliveryAppointment, "YYYYMMDDHHmmss").format("HH:mm")}</div>
                                </Box>
                            ) : (
                                ""
                            )
                        ) : column.id === "DeliveryStatus" ? (
                            formatDeliveryStatus(row?.DeliveryStatus)
                        ) : column.id === "DeliveryEta" ? (
                            row.DeliveryEta ? (
                                <Box>
                                    <div>
                                        {moment(row.DeliveryEta, "YYYYMMDDHHmmss").format("MM/DD/YYYY")}
                                    </div>
                                    <div>{moment(row.DeliveryEta, "YYYYMMDDHHmmss").format("HH:mm")}</div>
                                </Box>
                            ) : (
                                ""
                            )
                        ) : null}
                    </TableCell>
                ))}
            </TableRow>
            <TableRow
                sx={{
                    "& > *": {
                        backgroundColor:
                            index % 2 === 0 ? theme.palette.primary.main : theme.palette.secondary.main,
                    },
                }}
            >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <Box
                            sx={{
                                marginLeft: "200px",
                                marginTop: "20px",
                                paddingBottom: "16px",
                                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <Grid container spacing={2}>
                                {[
                                    ["Customer", row?.CustomerName || ''],
                                    ["Notes", row?.Notes || ''],
                                    ["Reschedule Date", row?.RescheduleDate || ''],
                                    ["Customer Region", row?.ShipmentDetails.CustomerRegion || ''],
                                ].map(([title, value]) => (
                                    <Grid item xs={12} sm={6} md={5} lg={3} key={title}>
                                        <Box
                                            sx={{
                                                width: "100%",
                                                boxSizing: "border-box",
                                                marginBottom: "8px",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: 500,
                                                    fontFamily: "Poppins, sans-serif",
                                                    fontSize: "16px",
                                                    lineHeight: "24px",
                                                    textAlign: "left",
                                                }}
                                            >
                                                {title}
                                            </Typography>

                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins, sans-serif",
                                                    fontSize: "16px",
                                                    fontWeight: 400,
                                                    lineHeight: "24px",
                                                    letterSpacing: "0.5px",
                                                    textAlign: "left",
                                                }}
                                            >
                                                {[
                                                    "Delivery ETA",
                                                    "Reschedule Date",
                                                ].includes(title)
                                                    ? value
                                                        ? moment(value, "YYYYMMDDHHmmss").format("MM/DD/YYYY HH:mm:ss")
                                                        : ""
                                                    : value}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

const removeDuplicateShipments = (shipments) => {
    const uniqueMap = new Map();

    shipments.forEach(shipment => {
        // Use composite key of just DeliveryNumber and NBNumber
        const key = `${shipment.DeliveryNumber}-${shipment.NBNumber}`;

        // Keep the most recently updated record
        if (!uniqueMap.has(key) ||
            (shipment.UpdatedTime && uniqueMap.get(key).UpdatedTime &&
                shipment.UpdatedTime > uniqueMap.get(key).UpdatedTime)) {
            uniqueMap.set(key, shipment);
        }
    });

    return Array.from(uniqueMap.values());
};

export default function CollapsibleTable({
    appliedFilters,
    onRowsChange,
    accountInfo,
    onFetchError,
}) {
    const [rows, setRows] = useState([]);
    const [error, setError] = useState(null);
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSorting, setIsSorting] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [allFetchedRows, setAllFetchedRows] = useState([]);
    const theme = useTheme();
    const isInitialMount = useRef(true);
    const { showErrorToast } = useToastContext();

    const fetchData = useCallback(
        async (isInitialFetch = false) => {
            if (!hasMore && !isInitialFetch) return;

            try {
                setIsLoading(true);
                const filters = appliedFilters || {};
                const data = await fetchShipments(
                    filters,
                    sortBy,
                    sortOrder,
                    isInitialFetch ? 1 : page,
                    accountInfo  // Pass accountInfo here
                );

                onFetchError(false);
                setRows((prevRows) => {
                    // If it's an initial fetch, just use the new data
                    if (isInitialFetch) {
                        const uniqueShipments = removeDuplicateShipments(data.shipments);
                        setAllFetchedRows(uniqueShipments);
                        onRowsChange(uniqueShipments);
                        return uniqueShipments;
                    }

                    // For subsequent fetches, combine with existing data and deduplicate
                    const combinedShipments = [...prevRows, ...data.shipments];
                    const uniqueShipments = removeDuplicateShipments(combinedShipments);
                    setAllFetchedRows(uniqueShipments);
                    onRowsChange(uniqueShipments);
                    return uniqueShipments;
                });

            //         // Check for invalid warehouse numbers in the response
            //    if (data.invalidWarehouseNumbers && data.invalidWarehouseNumbers.length > 0) {
            //     //  showErrorToast(`Invalid warehouse numbers: ${data.invalidWarehouseNumbers.join(', ')}`)
            //      showErrorToast("Enter valid Warehouse(s)")
            //     } 

                setHasMore(data.hasMore);
                setPage(data.nextPage);
                setError(null);
                setTotalCount(data.totalCount);
            } catch (error) {
                console.error("Error fetching shipments:", error);
                // if (error.response) {
                //     if (error.response.data) {
                //       if (Array.isArray(error.response.data.invalidWarehouseNumbers) && error.response.data.invalidWarehouseNumbers.length > 0) {
                //         showErrorToast("Enter valid Warehouse(s)");
                //       } else if (typeof error.response.data.invalidWarehouseNumbers === 'string' && error.response.data.invalidWarehouseNumbers.length > 0) {
                //         showErrorToast("Enter valid Warehouse(s)");
                //       }
                //     }
                //   }
                onFetchError(true);
                setError(
                    error.response?.status === 404
                        ? "No shipments are available"
                        : "Failed to fetch shipments"
                );
                setHasMore(false);
            } finally {
                setIsLoading(false);
                setIsSorting(false);
            }
        },
        [appliedFilters, sortBy, sortOrder, page, hasMore, accountInfo]  // Add accountInfo to dependencies
    );


    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            fetchData(true);
        } else if (appliedFilters || sortBy || sortOrder) {
            setRows([]);
            setPage(1);
            setHasMore(true);
            setTotalCount(0);
            setError(null);
            fetchData(true);
        }
    }, [appliedFilters, sortBy, sortOrder]);

    useEffect(() => {
        const ws = new WebSocket(config.websocketURL);

        ws.onopen = () => {
            console.log("Connected to WebSocket server");
        };

        ws.onmessage = (event) => {
            const receivedData = JSON.parse(event.data);
            console.log("Received data:", receivedData);

            if (Array.isArray(receivedData)) {
                setError(null);
                setRows((prevRows) => {
                    let updatedRows = [...prevRows];

                    receivedData.forEach((incomingData) => {
                        const existingRowIndex = updatedRows.findIndex(
                            (row) =>
                                row.DeliveryNumber === incomingData.DeliveryNumber &&
                                row.NBNumber === incomingData.NBNumber &&
                                row.StopSequence === incomingData.StopSequence
                        );

                        if (existingRowIndex !== -1) {
                            // Update existing row
                            updatedRows[existingRowIndex] = {
                                ...updatedRows[existingRowIndex],
                                ...incomingData,
                                ShipmentDetails: incomingData.ShipmentDetails,
                            };
                        } else {
                            // Add new row
                            updatedRows.push(incomingData);
                        }
                    });

                    console.log("Updated rows:", updatedRows);
                    return updatedRows;
                });
            }
        };

        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        return () => {
            ws.close();
        };
    }, []);

    const handleSort = useCallback(
        (columnId) => {
            if (isSorting) return;
            setIsSorting(true);
            if (sortBy === columnId) {
                setSortOrder(sortOrder === "ascending" ? "descending" : "ascending");
            } else {
                setSortBy(columnId);
                setSortOrder("ascending");
            }
        },
        [sortBy, sortOrder, isSorting]
    );

      const columns = [
        { id: "DeliveryNumber", label: "Delivery No.", width: "12%", sortable: true },
        { id: "PONumber", label: "PO Number", width: "12%", sortable: true },
        { id: "WareHouseNbr", label: "Warehouse", width: "12%", sortable: true },
    	{ id: "City", label: "City", width: "12%", sortable: false },
    	{ id: "State", label: "State", width: "12%", sortable: false },
        { id: "DeliveryAppointment", label: "Delivery Appointment", width: "12%", sortable: true },
        { id: "DeliveryStatus", label: "Delivery Status", width: "12%", sortable: true },
        { id: "DeliveryEta", label: "Delivery ETA", width: "12%", sortable: true },
      ];

    return (
        <Paper sx={{ width: "100%", overflow: "hidden", margin: "20px", marginBottom: 0 }}>
            {error ? (
                <Typography
                    sx={{
                        padding: "16px",
                        fontSize: "24px",
                        fontWeight: "medium",
                        textAlign: "center",
                        color: theme.palette.text.primary,
                    }}
                >
                    No shipments are available
                </Typography>
            ) : (
                <>
                    <Box sx={{ overflow: "auto", position: "relative" }}>
                        <Table aria-label='collapsible table' stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            sx={{
                                                fontWeight: "bold",
                                                width: column.width,
                                                backgroundColor: theme.palette.background.paper,
                                                zIndex: 1,
                                                padding: "16px 8px",
                                                borderBottom: `1px solid ${theme.palette.divider}`,
                                                textAlign: column.id === "DeliveryNumber" ? "center" : "left",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: column.id === "DeliveryNumber" ? "center" : "flex-start",
                                                }}
                                            >
                                                <span>{column.label}</span>
                                                {column.sortable && (
                                                    <ArrowDownwardOutlinedIcon
                                                        sx={{
                                                            color: isSorting ? "#ccc" : "#006098",
                                                            fontSize: "16px",
                                                            cursor: isSorting ? "not-allowed" : "pointer",
                                                            transform:
                                                                sortBy === column.id && sortOrder === "ascending"
                                                                    ? "rotate(180deg)"
                                                                    : "rotate(0deg)",
                                                            marginLeft: "4px",
                                                        }}
                                                        onClick={() => !isSorting && handleSort(column.id)}
                                                    />
                                                )}
                                            </Box>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                        </Table>
                    </Box>
                    <Box sx={{ height: "calc(91vh - 300px)", overflow: "auto" }} id='scrollableDiv'>
                        <InfiniteScroll
                            dataLength={rows.length}
                            next={() => fetchData(false)}
                            hasMore={hasMore}
                            loader={
                                <Typography sx={{ textAlign: "center", padding: "16px" }}>Loading ...</Typography>
                            }
                            scrollableTarget='scrollableDiv'
                            initialLoad={false}
                        >
                            <Table>
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <Row
                                            key={`${row.DeliveryNumber}-${index}`}
                                            row={row}
                                            index={index}
                                            columns={columns}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </InfiniteScroll>
                    </Box>
                    {rows.length > 0 && (
                        <Typography sx={{ textAlign: "center", padding: "16px" }}>
                            Showing {rows.length} of {totalCount} shipments
                        </Typography>
                    )}
                </>
            )}
            {isLoading && rows.length === 0 && (
                <Typography sx={{ textAlign: "center", padding: "16px" }}>Loading...</Typography>
            )}
        </Paper>
    );
}
