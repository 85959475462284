import React, { useState } from "react";
import "./App.css";
import NavigationContainer from "./navigation/Navigation";
import { authProvider } from "./authProvider";
import { AzureAD } from "react-aad-msal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import AppContextProvider from "./context";
import { getCachedThemeDetails, setThemeDetailsToCache } from "./api/addUser";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(getCachedThemeDetails() ?? false);

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
      background: {
        default: isDarkMode ? "#101418" : "#FFFFFF",
        paper: isDarkMode ? "#101418" : "#FFFFFF",
      },
      text: {
        primary: isDarkMode ? "#FFFFFF" : "#1D1B20",
        secondary: isDarkMode ? "#A9A9A9" : "#1D1B20",
        button: isDarkMode ? "#FFFFFF" : "#006098",
        buttonhover: isDarkMode ? "#FFFFFF" : "#FFFFFF",
      },
      primary: { main: isDarkMode ? "#101418" : "#E8F2FF" },
      secondary: { main: isDarkMode ? "#2D3135" : "#FFFFFF" },
      button: { main: isDarkMode ? "#2D3135" : "#006098" },
      buttonhover: { main: isDarkMode ? "#004766" : "#004766" },
      border: { main: isDarkMode ? "1px solid #FFFFFF" : "1px solid #006098" },
      FocusStyle: { main: isDarkMode ? "#FFFFFF" : "#006098" },
    },
  });

  const handleThemeChange = () => {
    setThemeDetailsToCache(!isDarkMode)
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppContextProvider>
        <div>
        <AzureAD provider={authProvider} forceLogin={true}>
          {(azureProps) => {
            return (
              <AuthenticatedRoute
                {...azureProps}
                onThemeChange={handleThemeChange}
              />
            );
          }}
        </AzureAD>
        </div>
      </AppContextProvider>
    </ThemeProvider>
  );
}

export default App;